import React, { useContext } from 'react'
import classes from './PostPage.module.scss'
import Reactmarkdown from 'react-markdown'
import { GlobalStateContext } from '../../../context/GlobalContextProvider'

import Grid from '@material-ui/core/Grid'
import { graphql, navigate } from 'gatsby'

import IosArrowBack from 'react-ionicons/lib/IosArrowBack'

import LogoFacebook from 'react-ionicons/lib/LogoFacebook'
import LogoInstagram from 'react-ionicons/lib/LogoInstagram'
import Youtube from '../../Youtube/Youtube'

import Layout from '../../../hoc/layout/layout'
import SEO from '../../../hoc/seo'
import Button from '../../UI/Button/Button'

export const query = graphql`
  query($slug: String!, $language: String!) {
    directusPostsTranslation(slug: { eq: $slug }) {
      title
      language
      topic
      post_details
      slug
      post {
        created_on(formatString: "MMM Do, YYYY")
      }
      post_image {
        data {
          full_url
        }
      }
      youtube_video_id
    }
    directusDodatkoweTranslation(language: { eq: $language }) {
      powrot
      language
    }
  }
`

const PostPage = props => {
  const state = useContext(GlobalStateContext)
  console.log(state.lang)

  let movie = (
    <Grid item xs={12} className={classes.movieBox}>
      <Youtube
        videoId={`${props.data.directusPostsTranslation.youtube_video_id}`}
        className={classes.movie}
      />
      <Button
        onClick={() => navigate(`/`)}
        onKeyDown={() => navigate(`/`)}
        tabIndex={0}
        role="button"
      >
        {props.data.directusDodatkoweTranslation.powrot}
      </Button>
    </Grid>
  )
  if (!props.data.directusPostsTranslation.youtube_video_id) {
    movie = null
  }
  return (
    <Layout>
      <SEO title={props.data.directusPostsTranslation.title} />
      <div className={classes.postContainer}></div>
      <Grid container style={{ minHeight: '70vh' }}>
        <Grid xs={12} className={classes.titleBox}>
          <div
            className={classes.backButton}
            onClick={() => navigate('/blog')}
            onKeyDown={() => navigate('/blog')}
          >
            <IosArrowBack color="#c69c6d" />
            Blog
          </div>
          <div className={classes.title}>
            <h3>{props.data.directusPostsTranslation.title}</h3>
          </div>
          <div className={classes.image}>
            <div className={classes.social}>
              <div className={classes.item}>
                <p>
                  {props.data.directusPostsTranslation.post.created_on
                    ? props.data.directusPostsTranslation.post.created_on
                    : ''}
                </p>
              </div>

              <LogoFacebook style={{ cursor: 'pointer' }} />
              <LogoInstagram style={{ cursor: 'pointer' }} />
              <p
                className={classes.topicLink}
                onClick={() =>
                  navigate(
                    `${props.data.directusPostsTranslation.language}/topic/${props.data.directusPostsTranslation.topic}`
                  )
                }
                onKeyDown={() =>
                  navigate(
                    `${props.data.directusPostsTranslation.language}/topic/${props.data.directusPostsTranslation.topic}`
                  )
                }
              >
                {props.data.directusPostsTranslation.topic}
              </p>
            </div>

            <div
              className={classes.postImage}
              style={{
                backgroundImage: props.data.directusPostsTranslation.post_image
                  .data.full_url
                  ? `url(${props.data.directusPostsTranslation.post_image.data.full_url})`
                  : null,
              }}
            ></div>
          </div>
        </Grid>
        {/* details box */}
        <Grid item xs={12} className={classes.detailsBox}>
          <div className={classes.details}>
            <Reactmarkdown
              source={props.data.directusPostsTranslation.post_details}
            />
          </div>
        </Grid>
        {movie}
      </Grid>
    </Layout>
  )
}

export default PostPage
