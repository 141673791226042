import React from 'react'
import YouTube from 'react-youtube'

const Youtube = props => {
  const opts = {
    height: '200',
    width: '360',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  }
  const onReady = e => {
    e.target.pauseVideo()
  }
  return (
    <div>
      <YouTube
        videoId={props.videoId}
        opts={opts}
        onReady={onReady}
        className={props.className}
      />
    </div>
  )
}

export default Youtube
